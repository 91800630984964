import { Box, Grid, Typography } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import FilterSeller from '../components/common/filterSeller';
import i18n from '../components/common/i18n';
import Columns from '../constants/columns/columnsListSellers';
import { branchStatusMapper } from '../utils/statusMapper';

const ListSellers = () => {
  const [results, setResults] = useState([]);
  const [sellersMap, setSellersMap] = useState([]);

  const options = {
    textLabels: {
      body: {
        noMatch: 'No se encuentran resultados para la búsqueda realizada',
      },
    },
    selectableRows: 'none',
    responsive: 'standard',
    filter: false,
    search: false,
  };

  const mapper = (data) => {
    const newArray = data;
    for (let i = 0; i < data.length; i += 1) {
      newArray[i].status = branchStatusMapper(data[i].status);
    }
  };

  const showResults = (sellersResults) => {
    mapper(sellersResults);
    setResults(sellersResults);
    setSellersMap(Object.fromEntries(sellersResults.map((obj) => [obj.id, obj])));
    console.log(sellersResults);
    console.log(sellersMap);
  };

  Columns[Columns.length - 1].options = {
    customBodyRender: (value) => (
      <Link
        to={{
          pathname: `/detalles-vendedor/${value}`,
          state: { seller: sellersMap[value] },
        }}
      >
        {i18n.listSellersSeeDetail}
      </Link>
    ),
  };

  return (
    <>
      <Grid container>
        <Grid item xs={1} />
        <Grid item xs={10}>
          <Box my={10}>
            <Typography variant="h2">{i18n.listSellersTitle}</Typography>
            <Box mb={5} />
            <FilterSeller showResults={showResults} />
            <MUIDataTable
              title={i18n.listSellersGridTitle}
              data={results}
              columns={Columns}
              options={options}
            />
          </Box>
        </Grid>
        <Grid item xs={1} />
      </Grid>
    </>
  );
};

export default ListSellers;
