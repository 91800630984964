/* eslint-disable no-template-curly-in-string */
/* eslint-disable max-len */
const i18n = {

  // error codes
  AUTHENTICATION_REFRESH_TOKEN_ERROR: 'Error de Authenticación',
  COMMERCE_AFFILIATION_REQUEST_GENERIC_ERROR: 'COMMERCE_AFFILIATION_REQUEST_GENERIC_ERROR',
  COMMERCE_AFFILIATION_REQUEST_STATUS_ERROR: 'La solicitud no tiene un estatus válido para ser firmado',
  COMMERCE_AFFILIATION_REQUEST_UPLOAD_DOCUMENT_ERROR: 'Ocurrió un error con la carga del archivo',
  COMMERCE_AFFILIATION_REQUEST_DOCUMENT_TYPE_ERROR: 'El tipo de archivo no esta permitido',
  COMMERCE_AFFILIATION_REQUEST_DOCUMENT_INVALID_MIME_TYPE_ERROR: 'El formato de archivo es inválido',
  COMMERCE_AFFILIATION_REQUEST_NOSIS_REPORT_FILE_ERROR: 'COMMERCE_AFFILIATION_REQUEST_NOSIS_REPORT_FILE_ERROR',
  COMMERCE_AFFILIATION_REQUEST_PUBLIC_URL_IMAGE: 'COMMERCE_AFFILIATION_REQUEST_PUBLIC_URL_IMAGE',
  COMMERCE_AFFILIATION_REQUEST_CANNOT_SIGN_IF_NOT_COMPLETED: 'Aún no terminaste de carga todos los datos requeridos',
  COMMERCE_AFFILIATION_REQUEST_ERROR_SAVING_IN_SAPP: 'Hubo un problema dando de alta en SAPP el comercio, contactá a soporte para que revise el caso manualmente. ---> ERROR: ${0}',
  COMMERCE_AFFILIATION_REQUEST_ERROR_SAVING_IN_SAPP_EMAIL_DUPLICATED: 'Hubo un problema dando de alta en SAPP el comercio, el email ingresado para responsale de comercio ya existe.',
  COMMERCE_AFFILIATION_REQUEST_INVALID_PHONE: 'Telefono inválido, revise por favor',
  COMMERCE_BRANCH_REQUEST_USER_ALREADY_EXIST: 'El DNI ingresado ya fue cargado en otro usuario, revise por favor',

  // Welcome dashboard
  welcomeDashboard: 'Bienvenido',

  // Menu Option
  menuOptionExit: 'Salir',

  // Format handlers
  invalidFormat: 'Formato Inválido',
  valueRequired: '${0} requerido',
  onlyNumbersAllowed: 'Solo ingresar números',
  maxLenght: 'Debe poseer como máximo ${0} caracteres',
  minLenght: 'Debe poseer al menos ${0} caracteres. Verificá estar ingresando el cod. de área sin 0 adelante, y sin anteponer el 15 para celulares',
  documentNumberMin: 'El DNI debe poseer al menos 7 caracteres',
  documentNumberMax: 'El DNI debe poseer como máximo 8 caracteres',

  // Roles
  rolAdmin: 'ADMIN',
  rolCommercial: 'COMMERCIAL',

  // General Commerce States
  commercePreApproved: 'PRE_APPROVED',
  commerceApproved: 'APPROVED',
  commerceRejected: 'REJECTED',
  commerceException: 'EXCEPTION',
  commerceCodeObserved: 'Observado',
  commerceInitiated: 'INITIATED',
  commerceCompleted: 'COMPLETED',
  commerceFinished: 'FINISHED',
  commerceApprovedSpanish: 'APROBADO',
  commercePreApprovedSpanish: 'PRE APROBADO',
  commerceRejectedSpanish: 'RECHAZADO',
  commerceInitiatedSpanish: 'INICIALIZADO',
  commerceCompletedSpanish: 'COMPLETADO',
  commerceFinishedSpanish: 'FINALIZADO',
  commerceStatusChanged: 'STATUS_CHANGED',
  commerceStatusChangedSpanish: 'CAMBIO DE ESTADO',
  commerceExceptionSpanish: 'EXCEPCIÓN',

  // General Branch status
  branchCreated: 'CREATED',
  branchCompleted: 'COMPLETED',
  branchFinalized: 'FINALIZED',
  branchCreatedSpanish: 'CREADA',
  branchCompletedSpanish: 'COMPLETADA',
  branchFinalizedSpanish: 'FINALIZADA',

  // New Commerce
  newCommerceQualify: 'Calificar Comercio',
  newCommerce: 'Adhesión de Comercios',
  newCommerceCalificationButton: 'Calificar',
  newCommerceCuit: 'CUIT',
  newCommerceName: 'Razon Social',
  newCommerceFantasyName: 'Nombre Fantasía',
  newCommerceEmail: 'Email (opcional)',
  newCommerceInstagram: 'Instagram (opcional)',
  newCommerceWebsite: 'Página web (opcional)',
  newCommerceObservations: 'Observaciones (opcional)',
  newCommerceObservationsHelper: 'Dejá una referencia del comercio, por ejemplo si ya trabajo con otra financiera, si es un conocido de alguien, etc..',
  newCommerceSeeDetail: 'Ver Detalle',
  newCommerceWarningText: 'Ocurrió un error con la carga del formulario. Intente recargar la página y cargar los datos nuevamente',
  newCommerceWarningTextCUIT: 'Hubo un error al consultar AFIP con el cuit ${0}, por favor revisá el dato ingresado o volvé a intentar en unos minutos',
  newCommerceAlreadyExist: 'Ya existe una solicitud  de adhesión para este CUIT en estado: ',
  newCommerceSuccessText: 'Se registraron los datos correctamente! El comercio se encuentra en estado PRE APROBADO!',
  newCommerceRejectedText: 'Se registraron los datos correctamente! El comercio se encuentra en estado RECHAZADO',
  newCommerceRequired: 'Requerido',
  newCommerceUsername: 'username',
  newCommercePassword: 'password',
  newCommerceMail: 'Correo electrónico',
  newCommercePasswordSpanish: 'Contraseña',
  newCommerceIncorrectPassword: 'Usuario y/o contraseña incorrectos',
  newCommerceSignIn: 'Ingresar',

  // Commerce/Branch Details
  detailsBussinessName: 'Razon Social: ',
  detailsName: 'Fantasia: ',
  detailsCuit: 'CUIT: ',
  detailsMotive: 'Motivo: ',
  detailsEmail: 'Email: ',
  detailsInstagram: 'Instagram: ',
  detailsWebSite: 'Página Web: ',
  detailsPhone: 'Teléfono: ',
  detailsProvince: 'Provincia: ',
  detailsTown: 'Localidad: ',
  detailsAddressName: 'Dirección: ',
  detailsAddressNumber: 'Altura: ',
  detailsPostalCode: 'Código postal: ',
  detailsCode: 'Código: ',
  detailsComments: 'Observaciones: ',
  detailsCreatedBy: 'Iniciada por: ',
  detailsState: 'Estado: ',
  detailsAprove: 'Aprobar',
  detailsReject: 'Rechazar',
  detailsException: 'Excepción',
  detailsSaveInSapp: 'Grabar en SAPP',
  detailsSaveInSappSuccess: 'Comercio guardado en SAPP',
  detailsButtonException: 'Pedido de excepción comercial',
  detailsMinimunCharactersException: 'Debe escribir al menos 20 caracteres',
  detailsCommentsTitle: 'Historial',
  detailsResponseMotorTitle: 'Respuesta Motor de Riesgos',
  detailsRiskMotorTitle: 'Motor de Riesgos',
  detailsCommerceTitle: 'Detalles de Solicitud de Adhesion',
  detailsDownloadNosis: 'Descargar Archivo Nosis',
  detailsDecisionEngineUser: 'DECISION_ENGINE_STATUS_CHANGE',
  detailsDecisionEngine: 'MOTOR DE RIESGOS',
  detailsDecisionEngineSpanish: 'RESPUESTA MOTOR DE RIESGOS',
  detailsDecisionEngineResponseName: 'Nombre',
  detailsDecisionEngineResponseValue: 'Valor',
  detailsCommerceFormLink: 'Formulario comercio: ',
  detailsCommerceBranchFormLink: 'Carga de sucursales y vendedores: ',
  detailsCommerceDataTitle: 'Datos cargados por el comercio',
  detailsCommerceImagesTitle: 'Imágenes cargadas por el comercio',
  detailsSendEmail: 'Se ha enviado el mail correctamente!',
  detailsExceptionAlreadyRequestedMessage: 'Ya se realizó un pedido de excepción para esta solicitud',
  detailsBranchManagerTitle: 'Detalles de encargado',
  detailsSellersTitle: 'Detalles de vendedores',
  detailsUserName: 'Nombre: ',
  detailsLastName: 'Apellido: ',
  detailsDocumentNumber: 'Nro Documento: ',
  detailsCellphone: 'Nro Celular: ',
  detailsBranchTitle: 'Detalle de Alta de Sucursal',
  copyToClipboard: 'Se ha copiado la url al portapapeles!',
  productDetailTitle: 'Detalle de producto',
  productLabel: 'Producto',
  subproductLabel: 'Subproducto',
  termLabel: 'Plazo',
  termsList: ['72hs', '30 días', '60 días'],
  addProductCTA: 'Agregar',
  saveProductCTA: 'Guardar',

  // Commerce Form
  formCommerceTitleCommerceData: 'Datos del comercio',
  formCommerceTitleCommerceManagerData: 'Datos del responsable del comercio',
  formCommerceTitleDocumentUpload: 'Cargar documentación',
  formCommerceTitleSignAndFinish: 'Firmar contrato de adhesión',
  formCommerceTaxId: 'CUIT',
  formCommerceCompanyName: 'Razon Social',
  formCommerceFantasyName: 'Fantasia',
  formCommerce_province: 'Provincia',
  formCommerce_city: 'Localidad',
  formCommerce_commerceProvince_description: 'Provincia',
  formCommerce_commerceCity_description: 'Localidad',
  formCommerce_postalCode: 'Código postal',
  formCommerce_addressName: 'Dirección comercial',
  formCommerce_addressNumber: 'Altura',
  formCommerce_floor: 'Piso',
  formCommerce_apartment: 'Dpto/Oficina',
  formCommerce_phone: 'Teléfono',
  formCommerce_phoneHelper: 'Cod. Área sin incluir ceros adelante. Ejemplo 1154673007',
  formCommerce_cellphone: 'Teléfono Celular',
  formCommerce_cellphoneHelper: 'Cod. Área sin incluir ceros adelante y sin el 15. Ejemplo 1154673007',
  formCommerce_email: 'Email',
  formCommerce_cbu: 'CBU asociado a la razón social',
  formCommerceCbuMinimumHelper: 'El CBU debe poseer al menos 22 caracteres',
  formCommerceCbuMaximumHelper: 'El CBU debe poseer como máximo 22 caracteres',
  formCommerceName: 'Nombre',
  formCommerceLastName: 'Apellido',
  formCommerce_documentNumber: 'Nro de DNI',
  formCommerceUploadDocumentDNIFront: 'Foto DNI Frente',
  formCommerceUploadDocumentDNIBack: 'Foto DNI Dorso',
  formCommerceUploadDocumentCUIT: 'Constancia de CUIT de AFIP',
  formCommerceUploadDocumentDNIFrontHelper: 'Foto DNI frente del responsables del comercio (.JPG o .PNG)',
  formCommerceUploadDocumentDNIBackHelper: 'Foto DNI dorso del responsables del comercio (.JPG o .PNG)',
  formCommerceUploadDocumentCUITHelper: 'Subir constancia de CUIT de la razón social (.JPG, .PNG o .PDF)',
  formCommerceDisclaimerPreview: 'Previsualización del contrato',
  formCommerceButtonEdit: 'Editar',
  formCommerceButtonSave: 'Guardar',
  formCommerceButtonSignAndSend: 'Firmar contrato',
  formCommerceErrorSavingData: 'Ocurrió un Error guardando la información!',
  formCommerceErrorUploadDocument: 'Ocurrió un error en la carga!',
  formCommerceErrorDeleteData: 'Ocurrió un error al eliminar los datos!',
  formCommerce_commerceManager_email: 'Mail titular: ',
  formCommerce_commerceManager_name: 'Nombre titular: ',
  formCommerce_commerceManager_lastName: 'Apellido titular: ',
  formCommerce_commerceManager_phone: 'Teléfono titular: ',
  formCommerce_commerceManager_documentNumber: 'DNI titular: ',
  formCommerce_signIsSucess: 'Gracias por completar la solicitud, estamos procesando el alta y un comercial de Credicuotas se pondrá en contacto con vos.',

  // General Filter Names
  filterName: 'name',
  filterFantasyName: 'fantasyName',
  filterTaxId: 'taxId',
  filterHeadOfficeTaxId: 'headOfficeTaxId',
  filterStatus: 'status',
  filterUser: 'user',
  filterDateFrom: 'dateFrom',
  filterDateTo: 'dateTo',

  // listCommerce
  listCommerceTitle: 'Listado de comercios',
  listCommerceGridTitle: 'Bandeja general de comercios',
  listCommerceSeeDetail: 'Ver Detalle',

  // aproveCommerce
  aproveCommerceGridTitle: 'Bandeja de aprobación de comercios',
  approveCommerceSeeDetail: 'Ver Detalle',

  // Ver Detalle Button
  viewDetailsButton: 'Ver Detalle',

  // filterCommerce
  filterCommerceTitle: 'Filtros',
  filterCommerceRazonSocial: 'Razón Social',
  filterCommerceFantasyName: 'Fantasía',
  filterCommerceCuit: 'CUIT',
  filterCommerceComercial: 'Comercial',
  filterCommerceState: 'Estado',
  filterCommerceDateFrom: 'Fecha Alta Desde',
  filterCommerceDateUpTo: 'Fecha Alta Hasta',
  filterCommerceSearch: 'Buscar',
  filterCommerceViewAll: 'VER TODOS',

  // Welcome commerce
  welcomeCommerceTitle: 'Hola ',
  welcomeCommerceButton: 'Adherite',
  welcomeCommerceDescription: 'Bienvenido, a continuacion te presentaremos un formulario para que cargue los datos del comercio y de la persona responsable del mismo. También te pediremos fotos del DNI y la contancia de CUIT ...',
  welcomeCommerceError: 'No se encuentran datos de la solicitud seleccionada',
  welcomeCommerceFirstP: '¡Incrementá tus ventas con la financiación de Credicuotas!',
  welcomeCommerceSecondP: 'En el próximo paso te pediremos que cargues los datos del comercio para la adhesión.',
  welcomeCommerceThirdP: 'Es un proceso completamente digital, solo necesitas:',
  welcomeCommerceFirstRequisite: '• Foto frente y dorso del DNI del titular y/o apoderado del comercio.',
  welcomeCommerceSecondRequisite: '• Número de CBU asociado a la razón social.',
  welcomeCommerceThirdRequisite: '• Firma de contrato de adhesión de comercios.',
  welcomeCommerceFourP: 'Empezá ya a vender tus productos con préstamos de Credicuotas.',

  // Welcome Branches
  welcomeBranchesManage: 'Gestionar sucursales',

  // List Branches
  listBranchesTitle: 'Listado de sucursales',
  listBranchesManage: 'Gestiona tus sucursales',
  listBranchesText: 'Desde acá vas a poder gestionar las sucursales de tu comercio y cargar nuevos vendedores. Es un proceso simple, donde te solicitamos algunos datos de tus sucursales, vamos necesitar:',
  listBranchesStreet: '● La dirección exacta de la sucursal',
  listBranchesContact: '● Un teléfono de contacto',
  listBranchesManagerData: '● Los datos de la persona encargada de la sucursal (DNI, tel, mail)',
  listBranchesVendorsData: '● Los datos de los vendedores de cada sucursal (DNI, tel, mail)',
  listBranchesNew: 'Cargar nueva sucursal',
  listBranchesDetails: 'Detalle sucursales',
  listBranchesBranch: 'Sucursal',
  listBranchesState: 'Estado',
  listBranchesCreateDate: 'Fecha creación',
  listBranchesLastModified: 'Fecha Últ. Modificación',
  listBranchesViewDetails: 'Ver detalle',
  listBranchesDelete: 'Eliminar',
  listBranchesAskDelete: 'Está seguro que desa eliminar el alta de sucursal ',
  listBranchesYes: 'Si',
  listBranchesNo: 'No',
  questionMark: '?',

  // Form Branches
  formBranchesManager: 'Datos del encargado de la sucursal',
  formBranchesData: 'Datos de la sucursal',
  formBranchesSellers: 'Datos de los vendedores',
  formBranchComercial: 'Comercial',
  formBranchAddressName: 'Dirección sucursal',
  formBranchesAddSeller: 'Agregar vendedor',
  formBranchesFinish: 'Finalizar y enviar datos',
  formBranchesComeBack: 'Volver',
  formBranchCompletedError: 'Ha ocurrido un error. Vuelva a intentarlo más tarde o contactese con su comercial',
  formBranchDataAlreadyExist: {
    mails: 'Los siguientes emails ya existen:',
    dnis: 'Los siguientes DNIs ya existen:',
  },

  // Form Seller
  formSeller: 'Formulario vendedor',
  formSellerAddError: 'Para agregar un vendedor debe tener cargado uno previamente',

  // listSellers
  listSellersTitle: 'Listado de vendedores',
  listSellersSeeDetail: 'Ver detalle',
  listSellersGridTitle: 'Bandeja general de vendedores',

  // filterSeller
  filterSellerTitleLabel: 'Filtros',
  filterSellerFirstNameLabel: 'Nombre',
  filterSellerFirstNameDetailLabel: 'Nombre: ',
  filterSellerFirstName: 'firstName',
  filterSellerLastNameLabel: 'Apellido',
  filterSellerLastNameDetailLabel: 'Apellido: ',
  filterSellerLastName: 'lastName',
  filterSellerIdentificationNumberLabel: 'DNI',
  filterSellerIdentificationNumberDetailLabel: 'DNI: ',
  filterSellerIdentificationNumber: 'identificationNumber',
  filterSellerEmailLabel: 'Email',
  filterSellerEmail: 'email',
  filterSellerEmailDetailLabel: 'Email: ',
  filterSellerCreatedDateFromLabel: 'Fecha Alta Desde',
  filterSellerCreatedDateFrom: 'createdDateFrom',
  filterSellerCreatedDateUpToLabel: 'Fecha Alta Hasta',
  filterSellerCreatedDateUpTo: 'createdDateTo',
  filterSellerStatusLabel: 'Estado',
  filterSellerStatus: 'status',

  filterSellerSearch: 'Buscar',
  filterSellerViewAll: 'VER TODOS',

  // detailsSeller
  detailsSellerTitleLabel: 'Detalle de Vendedor',
  detailsSellerCreatedDateLabel: 'Fecha de alta: ',
  detailsSellerCreatedByLabel: 'Creado por: ',
  detailsSellerBranchLabel: 'Nombre Sucursal: ',
  detailsSellerPhoneLabel: 'Celular: ',
  detailsSellerSaveInSappSuccessLabel: 'Usuario guardado en SAPP',

  // List Branches Filter
  listBranchesFilter: 'Bandeja general de sucursales',

  // Error message
  errorMessageTitle: 'Ups! tuvimos unos problemas con tu solicitud, por favor volve a reintentar en unos minutos.',
  errorMessageCTARetry: 'Volver a empezar',
  errorMessageCTAReturnCommerce: 'Volver a la tienda',
  errorMessageCellphone: 'El número de celular ingresado ya esta registrado con otro usuario.',
  errorMessageCellphoneCTA: 'Reintentar',

  // loading svg
  loadingMessage: 'Cargando...',
  loadingSVG1: 'M61.5 69.5H29c-4.1 0-7.4-3.3-7.4-7.4V29.7c0-4.1 3.3-7.4 7.4-7.4h32.4c4.1 0 7.4 3.3 7.4 7.4v32.4c.1 4.1-3.2 7.4-7.3 7.4z',
  loadingSVG2: 'M61.5 69.5H29c-4.1 0-7.4-3.3-7.4-7.4V29.7c0-4.1 3.3-7.4 7.4-7.4h32.4c4.1 0 7.4 3.3 7.4 7.4v32.4c.1 4.1-3.2 7.4-7.3 7.4z',
  loadingSVG3: 'M55.2 46.9c-.4-.8-.9-1.4-1.6-1.9-.7-.5-1.5-.9-2.3-1.2-.9-.3-1.8-.5-2.7-.6l-3.8-.5c-.9-.1-1.6-.3-2-.5-.4-.2-.7-.5-.7-1s.2-.9.7-1.2c.5-.3 1.4-.4 2.6-.4 1.1 0 2.1.1 3 .3.9.2 1.6.4 2.3.6.6.2 1.1.4 1.5.6.4.2.6.4.7.5.1 0 .2-.1.4-.3.2-.1.4-.3.7-.6l.6-.9c.2-.3.3-.7.3-1.2 0-.6-.2-1.1-.5-1.5-.3-.4-.7-.7-1.3-1-.9-.5-2-.8-3.3-1-.4-.1-.8-.1-1.3-.2V34c0-.9-.8-1.7-1.7-1.7h-3c-.9 0-1.7.8-1.7 1.7v1c-.5.1-.9.2-1.3.3-1.3.4-2.3.9-3.1 1.5-.8.7-1.4 1.4-1.8 2.3-.4.9-.6 1.8-.6 2.9 0 1.2.2 2.2.7 3.1.4.8 1 1.5 1.8 2s1.7.9 2.7 1.2c1 .3 2 .5 3.1.6l2.2.2c1 .1 1.8.3 2.3.5.5.2.7.6.7 1.2 0 .5-.3.9-.9 1.1-.6.2-1.5.3-2.6.3-1 0-2-.1-2.9-.2-.9-.1-1.7-.3-2.4-.5s-1.3-.4-1.8-.7c-.5-.2-.9-.4-1.1-.6l-2.4 4.3c.2.2.6.4 1.2.7.6.3 1.3.6 2.2.9.9.3 2 .5 3.3.8.2 0 .4.1.7.1v.9c0 .9.8 1.7 1.7 1.7h3c.9 0 1.7-.8 1.7-1.7V57c1.9-.3 3.5-.9 4.7-1.8 1.8-1.3 2.7-3.1 2.7-5.5 0-1.1-.3-2.1-.7-2.8z',

  // signatureBox
  clearSignature: '¿No te gusto? Repetir firma',
  errorSignature: 'Ocurrió un error con la firma!',
  sucessSignature: 'SE GRABO LA FIRMA CORRECTAMENTE',

  // noteBox
  noteBoxLabel: 'Escriba aquí el contenido de la nota a agregar...',
  noteBoxCTA: 'Agregar Nota',
  noteBoxMessageSuccess: 'Nota agregada exitosamente. Se envió una notificación por mail a los involucrados.',
  noteBoxMessageError: 'Error al intentar agregar la nota. Por favor vuelva a intentar más tarde.',

  // schemaValidation
  noteBoxSchemaValidationMessageRequired: 'El mensaje es requerido',
};

export default i18n;
