import DateFnsUtils from '@date-io/date-fns'; // choose your lib
import { Box, Grid } from '@material-ui/core';
import {
  DatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { Button, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { getBranchList, getBranchStatusList } from '../../api/services';
import { branchStatusMapper } from '../../utils/statusMapper';
import i18n from './i18n';
import Loading from './Loading';
import SelectMenuItems from './SelectMenuItems';

const filterBranches = ({ showResults }) => {
  const [isLoading, setIsLoading] = useState(false);

  const [branchesStates, setBranchesStates] = useState([{
    id: i18n.filterCommerceViewAll,
    value: i18n.filterCommerceViewAll,
  }]);

  const [filterValues, setFilterValues] = useState({
    pageNumber: 1,
    pageSize: 100,
    headOfficeTaxId: '',
    fantasyName: '',
    status: i18n.filterCommerceViewAll,
    dateFrom: null,
    dateTo: null,
  });

  if (filterValues.dateFrom === '') {
    filterValues.dateFrom = null;
  }
  if (filterValues.dateTo === '') {
    filterValues.dateTo = null;
  }

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFilterValues({ ...filterValues, [name]: value });
  };

  const handleInputChangeStatus = (e) => {
    const { name, value } = e.target;
    setFilterValues({ ...filterValues, [name]: branchStatusMapper(value) });
  };

  const handleDateChange = (e, name) => {
    const date = (e.toISOString());
    setFilterValues({ ...filterValues, [name]: date });
  };

  const filterBranchHandler = async () => {
    setIsLoading(true);
    try {
      if (filterValues.dateFrom === null) {
        filterValues.dateFrom = '';
      }
      if (filterValues.dateTo === null) {
        filterValues.dateTo = '';
      }
      filterValues.status = branchStatusMapper(filterValues.status);

      const { data } = await getBranchList(filterValues);

      filterValues.status = branchStatusMapper(filterValues.status);

      showResults(data);
    } catch (error) {
      console.error('There was an error!', error);
    }
    setIsLoading(false);
  };

  const getBranchesStatus = async () => {
    setIsLoading(true);
    try {
      const { data } = await getBranchStatusList();

      for (let i = 0; i < data.length; i += 1) {
        setBranchesStates((arr) => [...arr,
          {
            id: data[i], value: branchStatusMapper(data[i]),
          }]);
      }
    } catch (error) {
      console.error('There was an error!', error);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    getBranchesStatus();
  }, []);

  return (
    <>
      {isLoading === true && (
        <Loading />
      )}
      {isLoading === false && (
        <>
          <Box mb={5} sx={{ height: '500', backgroundColor: '#f5f3f4' }}>
            <Grid container sx={{ mx: '10px' }} spacing={5}>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  name={i18n.filterFantasyName}
                  value={filterValues.fantasyName}
                  onChange={handleInputChange}
                  label={i18n.filterCommerceFantasia}
                  type="search"
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  name={i18n.filterHeadOfficeTaxId}
                  value={filterValues.headOfficeTaxId}
                  onChange={handleInputChange}
                  label={i18n.filterCommerceCuit}
                  type="search"
                />
              </Grid>

              <Grid item xs={4}>
                <SelectMenuItems
                  title={i18n.filterCommerceEstado}
                  data={branchesStates}
                  value={branchStatusMapper(filterValues.status)}
                  onChange={handleInputChangeStatus}
                  name={i18n.filterStatus}
                />
              </Grid>
              <Grid item xs={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    variant="inline"
                    label={i18n.filterCommerceFechaAltaDesde}
                    value={filterValues.dateFrom}
                    name={i18n.filterDateFrom}
                    error={false}
                    helperText={null}
                    onChange={(e) => handleDateChange(e, i18n.filterDateFrom)}
                    format="dd/MM/yyyy"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={4}>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <DatePicker
                    variant="inline"
                    label={i18n.filterCommerceFechaAltaHasta}
                    value={filterValues.dateTo}
                    name={i18n.filterDateTo}
                    error={false}
                    helperText={null}
                    onChange={(e) => handleDateChange(e, i18n.filterDateTo)}
                    format="dd/MM/yyyy"
                  />
                </MuiPickersUtilsProvider>
              </Grid>
              <Grid item xs={12}>
                <Button variant="contained" onClick={filterBranchHandler}>
                  {i18n.filterCommerceFiltrar}
                </Button>
              </Grid>
            </Grid>
          </Box>
        </>
      )}
    </>
  );
};

filterBranches.propTypes = {
  showResults: PropTypes.func.isRequired,
};

export default filterBranches;
